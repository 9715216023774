import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import Adminservice from './service';
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment';
import { LinearProgress } from '@mui/material';
import AdminUserCreate from './create';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';

const service = new Adminservice();

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Username',
  },
  {
    id: 'password',
    numeric: false,
    disablePadding: false,
    label: 'Password',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Role',
  },
  {
    id: 'lastlogin',
    numeric: false,
    disablePadding: false,
    label: 'Last Login',
  },
  {
    id: 'edit',
    numeric: false,
    disablePadding: false,
    label: 'Edit',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  totalUsers: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, totalUsers, } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {totalUsers} Admin Accounts
        </Typography>
      )}

      {numSelected > 0 && totalUsers > 1 ? (
        <Tooltip title="Delete">
          <IconButton onClick={() => props.deleteSelected()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <FormControlLabel
          control={<Switch checked={props.dense} onChange={props.handleChangeDense} />}
          label="Dense"
        />)
      }
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function AdminUsersList() {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('password');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showCreateForm, setShowCreateForm] = useState(false);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    service.list().then(users => {
      setRows(users);
      setShowCreateForm(false);
      setLoading(false);
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const ChangePassword = ({ id }) => {
    const [showform, setShowForm] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");

    const validatePassword = (password) => {
      if (password.length <= 100 && password.length >= 0) {
        return true;
      }
      return false;
    }

    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
      setPasswordValid(validatePassword(e.target.value));
    };

    function formValid() {
      if (!passwordValid) return false;
      return true;
    }

    const submit = () => {
      setLoading(true);
      setSubmitted(false);
      setError(false);
      service.update(
        {
          id: id,
          password: password,
        }).then((response) => {
          setSubmitted(true);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error.response.detail);
          setSubmitted(false);
          setLoading(false);
          setError(true);
        });
    };

    if (showform) {
      return (
        <React.Fragment>

          <FormControl sx={{ m: 1 }} variant="outlined">
            <InputLabel htmlFor="password-edit">Password</InputLabel>
            <OutlinedInput
              id="password-edit"
              type="password"
              label="Password"
              onChange={handlePasswordChange}
              value={password}
              error={!passwordValid}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    {loading && <CircularProgress size="small" />}
                    {error && <ErrorIcon />}
                    {!loading && !submitted && (<SaveIcon
                      aria-label="submit"
                      disabled={!formValid()}
                      onClick={submit}
                    />)}
                    {!loading && submitted && <DoneIcon color="success" size="small" />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {/* <TextField
            type="password"
            id="password"
            label="Password"
            onChange={handlePasswordChange}
            value={password}
            error={!passwordValid}
            helperText={!passwordValid ? "Check password." : "Enter a valid password."}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="submit"
                  disabled={!formValid()}
                  onClick={submit}
                >
                  <SaveIcon />
                </IconButton>
              </InputAdornment>
            }
            size="small"
          /> */}
        </React.Fragment>
      );
    }
    else {
      return (
        <Button onClick={() => setShowForm(true)}>Change</Button>
      );
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleDeleteSelected = () => {
    const deletePromises = selected.map(id => service.delete(id));
    Promise.all(deletePromises).then(() => {
      setLoading(true);
      service.list().then(users => {
        setRows(users);
        setLoading(false);
      });
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <React.Fragment>
      <h1 className="page-title"><span className="fw-semi-bold">Admin Users</span></h1>
    <Box sx={{ width: '100%' }}>
        <EnhancedTableToolbar numSelected={selected.length} totalUsers={rows.length} dense={dense} handleChangeDense={handleChangeDense} deleteSelected={() => handleDeleteSelected()} />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.username)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row.id)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.username}
                    </TableCell>
                    <TableCell align="left"><ChangePassword id={row.id} /></TableCell>
                    <TableCell align="left">Administrator</TableCell>
                    <TableCell align="left">{row.last_login != null ? moment(row.last_login, moment.ISO_8601).format("MM/DD/YYYY hh:mm:ss") : "Never"}</TableCell>
                    <TableCell align="left">
                      <Tooltip title="Edit">
                        <IconButton>
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Box>
      {showCreateForm &&
        <Stack fullWidth direction="column" alignItems="center">
          <Paper style={{ marginTop: "2em", marginBottom: "2em", padding: "1em", minWidth: "15vw", maxWidth: "50vw" }} >
            <h3>New Admin User</h3>
            <p>Admin users are allowed to sign in to this website for base station management. Admin users automatically get NTRIP access to all mountpoints with up to 10 concurrent logins.</p>
            <Stack fullWidth spacing={2} direction="column" alignItems="center">
              <p>NTRIP users do not have access to this admin site. They cannot manage base settings or users.</p>
              <AdminUserCreate refresh={() => fetchData()} />
            </Stack>
          </Paper>
        </Stack>}
      {rows.length > 0 && !showCreateForm && <Button variant="contained" onClick={() => setShowCreateForm(true)}><PersonAddAltIcon />&nbsp;New Admin User</Button>}
    </React.Fragment>
  );
}